import { CssBaseline } from '@mui/material';
import { LazyMotion } from 'framer-motion';
import {
  Outlet,
  ScrollRestoration,
  createBrowserRouter,
  useRouteError,
} from 'react-router';

import { agentPortalRoute } from './agent-portal/routing';
import routes from './assets/constants/routes-no';
import { december2023Routes } from './competitions/2023/december/pages/routes';
import { advent2024Routes } from './competitions/2024/advent/pages/routes';
import { april2024Routes } from './competitions/2024/april/pages/routes';
import { february2024Routes } from './competitions/2024/february/pages/routes';
import { january2024Routes } from './competitions/2024/january/pages/routes';
import { march2024Routes } from './competitions/2024/march/pages/routes';
import { may2024Routes } from './competitions/2024/may/pages/routes';
import { summerExtra2024Routes } from './competitions/2024/summer-extra/pages/routes';
import { summer2024Routes } from './competitions/2024/summer/pages/routes';
import { wakeUp2024Routes } from './competitions/2024/wake-up/pages/routes';
import { february2025Routes } from './competitions/2025/february/pages/routes';
import { januaryOpen2025Routes } from './competitions/2025/january-open/pages/routes';
import { january2025Routes } from './competitions/2025/january/pages/routes';
import { Analytics } from './components/Analytics';
import Layout from './components/Layout';
import { NProgress } from './components/NProgress';
import { authInitQuery, competitionWinnersQuery } from './lib/api/queries';
import { simpleRace, smartLazy } from './lib/router-utils';
import LegacyRoutes from './pages/LegacyRoutes';
import { myScooprRoutes } from './pages/MyScoopr/routes';
import { queryClient } from './queryClient';
import { AuthProvider } from './services/Auth';
import { BrainSessionProvider } from './services/BrainSession/BrainSession';
import { SocketProvider } from './services/socket/SocketProvider';

const loadFeatures = () =>
  import('./framer-features.ts').then((res) => res.default);

// Can be moved to its own file in the future
// eslint-disable-next-line react-refresh/only-export-components
const RootLayout = () => (
  <>
    <NProgress />
    <CssBaseline />
    <ScrollRestoration />
    <LazyMotion features={loadFeatures} strict>
      <SocketProvider>
        <Outlet />
      </SocketProvider>
    </LazyMotion>
  </>
);

// Can be moved to its own file in the future
// eslint-disable-next-line react-refresh/only-export-components
const UserLayout = () => (
  <AuthProvider>
    <BrainSessionProvider>
      <Analytics />
      <Layout />
    </BrainSessionProvider>
  </AuthProvider>
);

// eslint-disable-next-line react-refresh/only-export-components
const ForwardError = () => {
  // Pass errors to sentry's error boundary higher up in the tree
  throw useRouteError();
};

export const router = createBrowserRouter([
  {
    path: '/',
    Component: RootLayout,
    // Ignore errors in production build (hopefully we'll get proper error screen in the future)
    ErrorBoundary: import.meta.env.PROD ? ForwardError : undefined,
    HydrateFallback: () => (
      <div className="full-page-loader">
        <img width="200" src="/logo.png" alt="Scoopr logo" />
      </div>
    ),
    children: [
      {
        // User portal
        loader: async () => {
          queryClient.prefetchQuery(authInitQuery());
          return null;
        },
        Component: UserLayout,
        children: [
          { path: '*', Component: LegacyRoutes },
          { path: '/', lazy: () => smartLazy(import('./pages/Home')) },
          {
            path: routes.PRODUCTS_REFINANCING,
            lazy: () => smartLazy(import('./pages/Refinancing')),
          },
          {
            path: routes.PRODUCTS_REFINANCING_CHAT,
            lazy: () => smartLazy(import('./pages/Refinancing')),
          },
          {
            path: routes.ABANDONED_SESSIONS_CHAT,
            lazy: () => smartLazy(import('./pages/Refinancing')),
          },
          {
            path: routes.DEBT_REGISTER,
            lazy: () => smartLazy(import('./pages/DebtRegister')),
          },
          {
            path: routes.CUSTOMER_SERVICE,
            lazy: () => smartLazy(import('./pages/CustomerService')),
          },
          {
            path: routes.COMPETITION_WINNERS,
            loader: () =>
              simpleRace([
                queryClient.prefetchQuery(competitionWinnersQuery()),
              ]),
            lazy: () => smartLazy(import('./pages/CompetitionWinners')),
          },
          ...myScooprRoutes,
          ...december2023Routes,
          ...january2024Routes,
          ...february2024Routes,
          ...march2024Routes,
          ...april2024Routes,
          ...may2024Routes,
          ...summer2024Routes,
          ...summerExtra2024Routes,
          ...wakeUp2024Routes,
          ...advent2024Routes,
          ...january2025Routes,
          ...januaryOpen2025Routes,
          ...february2025Routes,
        ],
      },
      agentPortalRoute,
    ],
  },
]);
